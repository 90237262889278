var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":""}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.title))])])],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_vm._l((_vm.payments),function(payment){return _c('v-container',{key:payment.id,staticClass:"payment mt-3 mb-1",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"subtitle-2"},on),[_vm._v(" "+_vm._s(payment.uniqueNo)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.payment-no')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"body-2"},on),[_vm._v(" "+_vm._s(_vm.formatDate(payment.paymentDate))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.payment-date')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:_vm.statusClass},on),[_vm._v(" "+_vm._s(_vm.formatStatus(payment.status))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.status')))])]),(_vm.screenSmall)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"body-2 red--text darken-1"},on),[_vm._v(" "+_vm._s(_vm.formatAmount(payment.totalAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.total-amount')))])]):_vm._e(),(_vm.screenSmall)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"body-2 indigo--text darken-4"},on),[_vm._v(" "+_vm._s(_vm.formatAmount(payment.paymentAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.payment-amount')))])]):_vm._e()],1),(!_vm.screenSmall)?_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"body-2 red--text darken-1"},on),[_vm._v(" "+_vm._s(_vm.formatAmount(payment.totalAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.total-amount')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"body-2 indigo--text darken-4"},on),[_vm._v(" "+_vm._s(_vm.formatAmount(payment.paymentAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.payment-amount')))])])],1):_vm._e(),_c('v-col',{staticClass:"text-right",attrs:{"cols":"auto","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":_vm.getPaymentUrl(payment.uniqueNo),"target":"_blank","icon":""}},on),[_c('v-icon',[_vm._v("mdi-book-open")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.view')))])])],1)],1)],1)}),(_vm.payments.length)?_c('v-pagination',{staticClass:"pt-4 pb-3",attrs:{"length":_vm.pageCount,"total-visible":"12"},on:{"input":_vm.load},model:{value:(_vm.pageNo),callback:function ($$v) {_vm.pageNo=$$v},expression:"pageNo"}}):_vm._e(),(!_vm.payments.length)?_c('v-container',{staticClass:"payment-no-data mt-3",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center body-2 my-2"},[_vm._v(_vm._s(_vm.$t("$vuetify.noDataText")))])],1)],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }